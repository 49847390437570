import React from 'react';
import { Grid } from '@material-ui/core';
import SnackbarMessage from '../../components/SnackBar/SnackBar'
const Dashboard = () => {
	return (
		<Grid container >
            <Grid item xs={12}>
				<SnackbarMessage />
				<SnackbarMessage />
            </Grid>
		</Grid>
	);
};

export default Dashboard;
