import * as types from '../types';
import { replaceArrayItemById } from '../utils';

const initialState = {
	isLoading: false,
	instructors: [],
	message: null,
	instructor: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.SET_INSTRUCTORS_LOADING:
			return { ...state, message: null, isLoading: action.payload };
		case types.FETCH_INSTRUCTORS_SUCCESS:
			return { ...state, instructors: action.payload, isLoading: false };
		case types.FETCH_INSTRUCTOR_START:
			return { ...state, instructor: null, isLoading: true };
		case types.FETCH_INSTRUCTOR_SUCCESS:
			return { ...state, instructor: action.payload, isLoading: false };
		case types.ADD_INSTRUCTOR_SUCCESS:
			return {
				...state,
				instructors: [ ...state.instructors, action.payload ],
				message: action.message,
				isLoading: false
			};
		case types.EDIT_INSTRUCTOR_SUCCESS:
			return {
				...state,
				instructors: replaceArrayItemById(
					state.instructors,
					action.payload._id,
					action.payload
				),
				message: action.message,
				instructor: action.payload,
				isLoading: false
			};
		case types.DELETE_INSTRUCTOR_SUCCESS:
			return {
				...state,
				instructors: state.instructors.filter(
					(instructor) => instructor._id !== action.payload
				),
				message: action.message,
				instructor: null,
				isLoading: false
			};
		case types.CLEAR_INSTRUCTOR:
			return { ...state, instructor: null };
		case types.CLEAR_INSTRUCTORS_MESSAGE:
			return { ...state, message: null };
		default:
			return state;
	}
};
