import * as types from '../types';
import axios from 'axios';
import { uploadImages } from '../utils';
import {setErrors, clearErrors} from './error-actions'

const setLoading = (isLoading = true) => ({type: types.SET_ACADEMIES_LOADING, payload: isLoading})

const handleErrorsDispatch = (err, dispatch) => {
	dispatch(setErrors(err.response.data));
	dispatch(setLoading(false))
}

const handleActionBeginDispatch = dispatch => {
	dispatch(setLoading())
	dispatch(clearErrors())
}


export const fetchAcademies = () => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.get('/api/v1/academy');
		dispatch({
			type: types.FETCH_ACADEMIES_SUCCESS,
			payload: res.data.data.data
		});
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const fetchAcademy = (id) => async (dispatch) => {
	console.log('fetching');
	try {
		dispatch({ type: types.FETCH_ACADEMY_START });
		dispatch(clearErrors())
		const res = await axios.get('/api/v1/academy/' + id);
		dispatch({ type: types.FETCH_ACADEMY_SUCCESS, payload: res.data.data.data });
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const addAcademy = (data, formData) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.post('/api/v1/academy', data);
		const images = await uploadImages(formData, res.data.data.data._id);
		dispatch({
			type: types.ADD_ACADEMY_SUCCESS,
			payload: { ...res.data.data.data, ...images },
			message: `Klub ${res.data.data.data.name} je uspešno dodat.`
		});
		setTimeout(() => dispatch({ type: types.CLEAR_ACADEMIES_MESSAGE }), 5000);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const editAcademy = (data, formData, id) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.patch('/api/v1/academy/' + id, data);
		const images = await uploadImages(formData, id);
		dispatch({
			type: types.EDIT_ACADEMY_SUCCESS,
			payload: { ...res.data.data.data, ...images },
			message: `Klub ${res.data.data.data.name} je uspešno izmenjen.`
		});
		setTimeout(() => dispatch({ type: types.CLEAR_ACADEMIES_MESSAGE }), 5000);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const deleteAcademy = (id, history) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		await axios.delete('/api/v1/academy/' + id);
		dispatch({
			type: types.DELETE_ACADEMY_SUCCESS,
			payload: id,
			message: `Klub je uspešno izbrisan.`
		});
		setTimeout(() => {
			dispatch({ type: types.CLEAR_ACADEMIES_MESSAGE });
			history.push('/klubovi');
		}, 1500);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const clearAcademy = () => ({ type: types.CLEAR_ACADEMY });
