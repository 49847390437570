import * as types from '../types';

const initialState = null;

export default (state = initialState, action) => {
	switch (action.type) {
        case types.CLEAR_ERRORS: 
            return initialState
        case types.SET_ERRORS:
            return action.payload
		default:
			return state;
	}
};
