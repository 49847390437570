import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	link: {
		display: 'flex',
		height:'48px',
		width: '100%',
		justifyContent: 'center',
		alignItems:'center',
		background:'transparent',
		trasnsition: 'all .2s',
		'&:hover': {
			background: 'linear-gradient(315deg, rgba(220, 220, 210,0.3) , rgba(210, 200, 190, 0.4))'
		}
	}
}));

const ScrollableTabsButtonAuto = ({ onTabChange, tabs, history, route }) => {
	const classes = useStyles();
	const [ value, setValue ] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		history.push(tabs[newValue].value);
	};

	let variant = window.innerWidth > 425 ? 'fullWidth' : 'scrollable';

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
				{/* {route !== '/forma' ? (
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant={variant}
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						{tabs.map((tab, i) => (
							<Tab label={tab.label} key={i} {...a11yProps(i)} />
						))}
					</Tabs>
				) : (
					<Box className={classes.link} aria-label="scrollable auto tabs example">
						<Link to="/" style={{width:'100%', textAlign:'center'}}>
							<Typography>Nazad</Typography>
						</Link>
					</Box>
				)} */}

				<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant={variant}
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						{tabs.map((tab, i) => (
							<Tab label={tab.label} key={i} {...a11yProps(i)} />
						))}
					</Tabs>
			</AppBar>
		</div>
	);
};

export default withRouter(ScrollableTabsButtonAuto);
