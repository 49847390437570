import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addCompetitor } from '../../redux/actions/competitors-actions';
import { clearErrors } from '../../redux/actions/error-actions';
import CompetitorForm from '../../components/Forms/CompetitorForm';


const initialState = {
	name: '',
	disciplines: '',
	category: '',
	bjjBelt: '',
	image: null,
	mma: {
		professional: {
			won: 0,
			lost: 0,
			draw: 0,
			accomplishments: ''
		},
		amateur: {
			won: 0,
			lost: 0,
			draw: 0,
			accomplishments: ''
		}
	},
	bjj: {
		won: 0,
		lost: 0,
		accomplishments: ''
	},
	otherAccomplishments: '',
	rang: 0,
	bjjRang: 0,
	generalRang: 0
};

const AddCompetitor = ({
	addCompetitor,
	message,
	isLoading,
	history,
	errors,
	clearErrors,
	errorMessage,
	competitors
}) => {
	
	const [ state, setState ] = useState(initialState);
	const [ image, setImage ] = useState(null);

	useEffect(
		() => {
			return () => {
				if (errors) {
					clearErrors();
				}
			};
		},
		[ clearErrors, errors ]
	);

	useEffect(
		() => {
				setState(initialState);
				setImage(null);
		},
		[ competitors.length]
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let formData = null
		const data = { ...state };
		delete data.image;

		if (image) {
			formData = new FormData();
			formData.append('competitor', image);
		}

		addCompetitor(data, formData, history)
	
		
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleMMAProChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({
			...prevState,
			mma: {
				...prevState.mma,
				professional: {
					...prevState.mma.professional,
					[name]: value && !isNaN(value) ? parseInt(value) : value
				}
			}
		}));
	};

	const handleMMAAmaChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({
			...prevState,
			mma: {
				...prevState.mma,
				amateur: {
					...prevState.mma.amateur,
					[name]: value && !isNaN(value) ? parseInt(value) : value
				}
			}
		}));
	};

	const handleBJJChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({
			...prevState,
			bjj: {
				...prevState.bjj,
				[name]: value && !isNaN(value) ? parseInt(value) : value
			}
		}));
	};

	const handleFileChange = (e) => {
		const { files } = e.target;
		setImage(files[0]);
	};

	const handleDisciplineChange = (e) => {
		const { value } = e.target;
		const disciplines = value.split(',').map((el) => el.trim());

		setState((prevState) => ({ ...prevState, disciplines }));
	};

	return (
		<CompetitorForm
			title={'Dodaj Takmičara'}
            values={state}
            handleSubmit={handleSubmit}
			handleChange={handleChange}
            handleFileChange={handleFileChange}
            handleMMAProChange={handleMMAProChange}
            handleMMAAmaChange={handleMMAAmaChange}
            handleDisciplineChange={handleDisciplineChange}
            handleBJJChange={handleBJJChange}
			isLoading={isLoading}
			message={message}
			errors={errors}
            errorMessage={errorMessage}
		/>
	);
};

const mapStateToProps = ({
	competitors: { isLoading, message, competitors },
	errors
}) => ({
	isLoading,
	message,
	competitors,
	errorMessage: errors && errors.message ? errors.message : null,
	errors: errors && errors.validationErrors ? errors.validationErrors : null
});

export default connect(mapStateToProps, {
	addCompetitor,
	clearErrors
})(AddCompetitor);
