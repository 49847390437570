import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = ({onConfirm, description, isOpen, handleVisibility}) => {
 
  const handleConfirm = () => {
    onConfirm()
    handleVisibility(false)
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => handleVisibility(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Da li želite da nastavite?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleVisibility(false)} color="primary">
            Nazad
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Nastavi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog