import React, { useEffect, useState, useRef } from 'react';
import {
	Grid,
	TextField,
	Button,
	Tooltip,
	Typography,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Input,
	Checkbox,
	ListItemText
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Form from './FormContainer';

const useStyles = makeStyles((theme) => ({
	inputGroup: {
		width: '100%'
	},
	buttons: {
		margin: '20px 10px 20px 0'
	},
	subheader: {
		marginBottom: '15px'
	},
	formControl: {
		width: '100%'
	}
}));

function getStyles(id, academies, theme) {
	const isSelected = academies.filter((el) => el._id === id).length === 0;
	return {
		fontWeight: isSelected
			? theme.typography.fontWeightRegular
			: theme.typography.fontWeightMedium
	};
}

const stringFromSelected = (selectedArr, academiesArr) => {
	return academiesArr
		.filter((academy) => selectedArr.includes(academy._id))
		.map((el) => el.location)
		.join(', ');
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};

const InstructorForm = ({
	values,
	academies,
	errors,
	handleChange,
	handleFileChange,
	handleBJJChange,
	handleDisciplineChange,
	handleMMAAmaChange,
	handleMMAProChange,
	handleAcademyChange,
	...otherProps
}) => {
	const style = useStyles();
	const theme = useTheme();
	const inputLabel = useRef(null);
	const [ labelWidth, setLabelWidth ] = useState(0);

	useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	const {
		name,
		disciplines,
		bjjBelt,
		mma: { professional, amateur },
		bjj,
		coachingAccomplishments,
		martialArts,
		other,
		about,
		academySelect,
		phone,
		email,
		rang
	} = values;

	return (
		<Form {...otherProps}>
			<Grid item xs={12}>
				<Button variant="contained" component="label" className={style.buttons}>
					Dodaj sliku
					<input
						type="file"
						style={{ display: 'none' }}
						name="image"
						onChange={handleFileChange}
					/>
				</Button>
			</Grid>

			<Grid item xs={6} sm={3}>
				<TextField
					error={errors && errors.name && true}
					id="name"
					label="Ime i Prezime"
					value={name}
					helperText={errors && errors.name ? errors.name : ''}
					variant="outlined"
					name="name"
					onChange={handleChange}
					className={style.inputGroup}
				/>
			</Grid>

			<Grid item xs={6} sm={3}>
				<FormControl variant="outlined" className={style.formControl}>
					<InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
						Lokacija
					</InputLabel>
					<Select
						labelId="demo-mutiple-name-label"
						id="demo-mutiple-name"
						value={academySelect}
						onChange={handleAcademyChange}
						labelWidth={labelWidth}
						input={<Input />}
						renderValue={(selected) => stringFromSelected(selected, academies)}
						multiple
						name="atAcademy"
						MenuProps={MenuProps}
					>
						{academies.map((academy, i) => (
							<MenuItem
								key={academy._id}
								value={academy._id}
								// style={getStyles(academy, academySelect, theme)}
							>
								{academy.location}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={4} sm={2}>
				<TextField
					error={errors && errors.bjjBelt && true}
					id="bjjBelt"
					label="Pojas"
					value={bjjBelt}
					helperText={
						errors && errors.bjjBelt ? (
							errors.bjjBelt
						) : (
							'Beli/Plavi/Ljubicasti/Braon/Crni'
						)
					}
					variant="outlined"
					name="bjjBelt"
					onChange={handleChange}
					className={style.inputGroup}
				/>
			</Grid>

			<Grid item xs={4} sm={2}>
				<TextField
					id="discipline"
					label="Discipline"
					value={disciplines}
					helperText="BJJ, MMA, Boks..."
					variant="outlined"
					name="disciplines"
					onChange={handleChange}
					className={style.inputGroup}
				/>
			</Grid>

			<Grid item xs={4} sm={2}>
				<TextField
					id="rang"
					label="Rang"
					value={rang}
					helperText="Za sortiranje...."
					variant="outlined"
					name="rang"
					onChange={handleChange}
					className={style.inputGroup}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				<TextField
					id="about"
					label="O Instruktoru"
					helperText="Za prelazak na novi red dodati zvezdicu *"
					multiline
					rows="3"
					value={about}
					variant="outlined"
					name="about"
					className={style.inputGroup}
					onChange={handleChange}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Tooltip
					title="Primer: BJJ Crni pojas prvi dan*Kung-fu master*Crni pojas Dzudo"
					placement="top-start"
				>
					<TextField
						id="other martialArts"
						label="Zvanja u veštinama"
						helperText="Za prelazak na novi red dodati zvezdicu *"
						multiline
						rows="3"
						value={martialArts}
						variant="outlined"
						name="martialArts"
						className={style.inputGroup}
						onChange={handleChange}
					/>
				</Tooltip>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Typography className={style.subheader}>MMA Profi</Typography>
				<Grid container spacing={1}>
					<Grid item xs={4}>
						<TextField
							id="mmaWon"
							label="Pobede"
							value={professional.won}
							helperText=""
							variant="outlined"
							name="won"
							onChange={handleMMAProChange}
							className={style.inputGroup}
						/>
					</Grid>

					<Grid item xs={4}>
						<TextField
							id="mmaLost"
							label="Porazi"
							value={professional.lost}
							helperText=""
							variant="outlined"
							name="lost"
							onChange={handleMMAProChange}
							className={style.inputGroup}
						/>
					</Grid>

					<Grid item xs={4}>
						<TextField
							id="mmaDraw"
							label="N / C"
							value={professional.draw}
							helperText=""
							variant="outlined"
							name="draw"
							onChange={handleMMAProChange}
							className={style.inputGroup}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id="MMA Pro Results"
							label="Rezultati"
							helperText="Za prelazak na novi red dodati zvezdicu *"
							multiline
							rows="5"
							value={professional.accomplishments}
							variant="outlined"
							name="accomplishments"
							onChange={handleMMAProChange}
							className={style.inputGroup}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Typography className={style.subheader}>MMA Amaterski</Typography>
				<Grid container spacing={1}>
					<Grid item xs={4}>
						<TextField
							id="mmaAmaWon"
							label="Pobede"
							value={amateur.won}
							helperText=""
							variant="outlined"
							name="won"
							onChange={handleMMAAmaChange}
							className={style.inputGroup}
						/>
					</Grid>

					<Grid item xs={4}>
						<TextField
							id="mmaAmaLost"
							label="Porazi"
							value={amateur.lost}
							helperText=""
							variant="outlined"
							name="lost"
							onChange={handleMMAAmaChange}
							className={style.inputGroup}
						/>
					</Grid>

					<Grid item xs={4}>
						<TextField
							id="mmaAmaDraw"
							label="N / C"
							value={amateur.draw}
							helperText=""
							variant="outlined"
							name="draw"
							onChange={handleMMAAmaChange}
							className={style.inputGroup}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id="MMA Ama Results"
							label="Rezultati"
							helperText="Za prelazak na novi red dodati zvezdicu *"
							multiline
							rows="5"
							value={amateur.accomplishments}
							variant="outlined"
							name="accomplishments"
							onChange={handleMMAAmaChange}
							className={style.inputGroup}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Typography className={style.subheader}>BJJ</Typography>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<TextField
							id="bjj won"
							label="Pobede"
							value={bjj.won}
							helperText=""
							variant="outlined"
							name="won"
							onChange={handleBJJChange}
							className={style.inputGroup}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField
							id="bjjLost"
							label="Porazi"
							value={bjj.lost}
							helperText=""
							variant="outlined"
							name="lost"
							onChange={handleBJJChange}
							className={style.inputGroup}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id="BJJ Results"
							label="Rezultati"
							helperText="Za prelazak na novi red dodati zvezdicu *"
							multiline
							rows="5"
							value={bjj.accomplishments}
							variant="outlined"
							name="accomplishments"
							className={style.inputGroup}
							onChange={handleBJJChange}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Typography className={style.subheader}>Ostalo</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<TextField
							id="Phone"
							label="Telefon"
							value={phone}
							variant="outlined"
							name="phone"
							className={style.inputGroup}
							onChange={handleBJJChange}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="Email"
							label="Email"
							value={email}
							variant="outlined"
							name="email"
							className={style.inputGroup}
							onChange={handleBJJChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="coaching Accomplishments"
							label="Trenerski rezultati"
							helperText="Za prelazak na novi red dodati zvezdicu *"
							multiline
							rows="5"
							value={coachingAccomplishments}
							variant="outlined"
							name="coachingAccomplishments"
							className={style.inputGroup}
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} sm={6}>
				<TextField
					id="other"
					label="Komentar"
					helperText="Za prelazak na novi red dodati zvezdicu *"
					multiline
					rows="5"
					value={other}
					variant="outlined"
					name="other"
					className={style.inputGroup}
					onChange={handleChange}
				/>
			</Grid>
		</Form>
	);
};

export default InstructorForm;
