import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';
import {LinearProgress} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import BG from './bg.jpg';
import { login } from '../../redux/actions/auth-actions';
import { connect } from 'react-redux';
import { clearErrors } from '../../redux/actions/error-actions'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	root: {
		height: '100%',
		maxHeight: '500px'
	},
	image: {
		backgroundImage: `url(${BG})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'dark'
				? theme.palette.grey[900]
				: theme.palette.grey[50],
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}));

const SignInSide = ({ login, isLoading, errorMessage, clearErrors }) => {
	const classes = useStyles();
	const [ state, setState ] = useState({ username: '', password: '' });

	useEffect(() => () => clearErrors(), [clearErrors])

	const handleSubmit = (e) => {
		e.preventDefault();
		login(state);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	return (
		<div className={classes.wrapper}>
			<Grid container component="main" className={classes.root}>
				<CssBaseline />
				<Grid item xs={false} sm={4} md={7} className={classes.image} />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
							{! errorMessage ? <LockOutlinedIcon /> : <ErrorOutlineIcon />}
						</Avatar>
						{!errorMessage && <Typography component="h1" variant="h5">
							Sign in
						</Typography>}
						{errorMessage && <Typography color="error">
							{errorMessage}
						</Typography>}
						<form className={classes.form} noValidate onSubmit={handleSubmit}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="username"
								label="Korisničko ime"
								name="username"
								autoComplete="username"
								autoFocus
								onChange={handleChange}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Šifra"
								type="password"
								id="password"
								autoComplete="current-password"
								onChange={handleChange}
							/>
            {isLoading && <LinearProgress />}
						{!isLoading && <Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Uloguj se
							</Button>}
						</form>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

const mapStateToProps = ({auth, errors}) => ({
  isLoading: auth.isLoading,
  errorMessage: errors && errors.message ? 'Pogrešno korisničko ime/šifra!' : null
})

export default connect(mapStateToProps, { login, clearErrors })(SignInSide);
