import React from 'react';
import { Container } from './styles';
import { connect } from 'react-redux';
import { deleteCompetitor } from '../../redux/actions/competitors-actions'
import Competitors from './Competitors'

const CompetitorsContainer = ({ competitors, isLoading, deleteCompetitor, history  }) => {

	const handleRemove = (id) => {
		deleteCompetitor(id, history )
	}

	return (
		<Container>
			<Competitors competitors={competitors} isLoading={isLoading} handleRemove={handleRemove}/>
		</Container>
	);
};

const mapStateToProps = ({ competitors }) => ({
	competitors: competitors.competitors,
	isLoading: competitors.isLoading
});

export default connect(mapStateToProps, {deleteCompetitor})(CompetitorsContainer);
