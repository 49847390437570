import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Avatar,
  Chip,
  Button,
  Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmDialog from '../Dialog/Dialog';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '10px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto 0 15px'
  },
  panel: {
    marginBottom: '10px'
  },
  chip: {
    width: '150px',
    borderRadius: '5px'
  },
  chipSmall: {
    width: '100px',
    borderRadius: '5px'
  },
  chipRang: {
    width: '40px',
    borderRadius: '5px',
    marginLeft: '10px'
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px'
  },
  button: {
    marginRight: theme.spacing(2),
    width: '85px',
    fontSize: theme.typography.pxToRem(12)
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const ExpPanel = ({
  title,
  children,
  image,
  chip,
  chipSize,
  editUrl,
  id,
  handleRemove,
  rang,
  bjjRang,
  generalRang
}) => {
  const classes = useStyles();
  const [isOpen, setDialogVisibility] = useState(false);

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Avatar src={image} align='center' variant='square' alt='instr' />
          <Typography className={classes.heading} align='center'>
            {title}
          </Typography>
          <Chip
            label={chip.toUpperCase()}
            className={!chipSize ? classes.chip : classes.chipSmall}
          />
          {generalRang ? (
            <Tooltip title='Generalni sort' placement='top'>
              <Chip label={generalRang} className={classes.chipRang} />
            </Tooltip>
          ) : null}
          {rang ? (
            <Tooltip title='MMA sort' placement='top'>
              <Chip label={rang} className={classes.chipRang} />
            </Tooltip>
          ) : null}
          {bjjRang ? (
            <Tooltip title='Bjj sort' placement='top'>
              <Chip label={bjjRang} className={classes.chipRang} />
            </Tooltip>
          ) : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {children}
        </ExpansionPanelDetails>
        <Box className={classes.buttonsContainer}>
          <Link to={editUrl}>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              startIcon={<EditIcon />}
              size='small'
            >
              Izmeni
            </Button>
          </Link>
          <Button
            variant='contained'
            color='secondary'
            className={classes.button}
            startIcon={<DeleteIcon />}
            size='small'
            onClick={() => setDialogVisibility(true)}
          >
            Izbriši
          </Button>
        </Box>
      </ExpansionPanel>
      <ConfirmDialog
        description='Podaci i slike će biti trajno obrisani.'
        onConfirm={() => handleRemove(id)}
        isOpen={isOpen}
        handleVisibility={setDialogVisibility}
      />
    </div>
  );
};

export default ExpPanel;
