export default () => {
	const currentYear = new Date().getFullYear();
	const firstYear = 2007;
	const allYears = [];

	for (let i = currentYear ; i >= firstYear; i--) {
		allYears.push({value:i, label:i});
	}

	return allYears;
};