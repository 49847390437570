import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { addAcademy } from '../../redux/actions/academies-actions'
import { clearErrors } from '../../redux/actions/error-actions'
import AcademyForm from '../../components/Forms/AcademyForm'

const initialState = {
  location: '',
  name: '',
  address: '',
  about: '',
  mapUrl: '',
  phone: '',
  place: '',
  email: '',
  classes: '',
  cover: '',
  rang: 0,
}

const AddAcademy = ({
  addAcademy,
  message,
  isLoading,
  history,
  errors,
  clearErrors,
  errorMessage,
  academies,
}) => {
  const [state, setState] = useState(initialState)
  const [images, setImages] = useState({ cover: null, classes: null })

  useEffect(() => {
    return () => {
      if (errors) {
        clearErrors()
      }
    }
  }, [clearErrors, errors])

  useEffect(() => {
    setState(initialState)
    setImages({ cover: null, classes: null })
  }, [academies.length])

  const handleSubmit = e => {
    e.preventDefault()
    let formData = null
    const { cover, classes } = images
    const data = { ...state }
    delete data.classes
    delete data.cover

    if (classes || cover) {
      formData = new FormData()
      if (classes) formData.append('academyClasses', classes)
      if (cover) formData.append('academyCover', cover)
    }
    addAcademy(data, formData, history)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }

  const handleFileChange = e => {
    const { files, name } = e.target
    setImages(prevState => ({ ...prevState, [name]: files[0] }))
  }

  return (
    <AcademyForm
      title={'Dodaj Klub'}
      values={state}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleFileChange={handleFileChange}
      isLoading={isLoading}
      message={message}
      errors={errors}
      errorMessage={errorMessage}
    />
  )
}

const mapStateToProps = ({ academies: { isLoading, message, academies }, errors }) => ({
  isLoading,
  message,
  academies,
  errorMessage: errors && errors.message ? errors.message : null,
  errors: errors && errors.validationErrors ? errors.validationErrors : null,
})

export default connect(mapStateToProps, {
  addAcademy,
  clearErrors,
})(AddAcademy)
