import React, { Fragment } from 'react';
import withLoader from '../../components/HOC/withLoader';
import InstructorPanel from '../../components/ExpPanel/Panels/InstructorPanel'
import Typography from '@material-ui/core/Typography'

const InstructorsList = ({ instructors, handleRemove }) => (
	<Fragment>
		{
				instructors.length 
				? 
				instructors.map((instructor) => (
				<InstructorPanel
					{...instructor}
					key={instructor._id}
					title={instructor.name}
					chip={instructor.atAcademy.length > -1 ? instructor.atAcademy.map(academy => academy.location).join(', ') : 'Nema Klub'}
					editUrl={`/instruktor/izmeni/${instructor._id}`}
					image={`/images/instructors/${instructor.image}`}
					handleRemove={handleRemove}
					id={instructor._id}
					generalRang={instructor.rang}
					rang={null}
				/>
				))
				:
				<Typography>Nema sačuvanih Instruktora</Typography>
			}
	</Fragment>
);



export default withLoader(InstructorsList)

