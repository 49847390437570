import React from 'react'
import { Grid, TextField, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Form from './FormContainer'

const useStyles = makeStyles(theme => ({
  inputGroup: {
    width: '100%',
  },
  buttons: {
    margin: '20px 10px 20px 0',
  },
  subheader: {
    marginBottom: '15px',
  },
}))

const CompetitorForm = ({
  values,
  errors,
  handleChange,
  handleFileChange,
  handleBJJChange,
  handleDisciplineChange,
  handleMMAAmaChange,
  handleMMAProChange,
  ...otherProps
}) => {
  const style = useStyles()
  const {
    name,
    disciplines,
    category,
    bjjBelt,
    otherAccomplishments,
    mma,
    bjj,
    rang,
    bjjRang,
    generalRang,
  } = values

  return (
    <Form {...otherProps}>
      <Grid item xs={12}>
        <Grid container spacing={2} justify='space-between'>
          <Grid item xs={6}>
            <Button variant='contained' component='label' className={style.buttons}>
              Dodaj sliku
              <input
                type='file'
                style={{ display: 'none' }}
                onChange={handleFileChange}
                name='image'
              />
            </Button>
          </Grid>
          <Grid item xs={4} sm={2}>
            <TextField
              id='generalRang'
              type='number'
              label='General Sort'
              value={generalRang}
              helperText='Rang za sortiranje stranice gde su svi takm.'
              variant='outlined'
              name='generalRang'
              onChange={handleChange}
              className={style.inputGroup}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <TextField
              id='bjjRang'
              type='number'
              label='BJJ Sort'
              value={bjjRang}
              helperText='Rang za sortiranje BJJ'
              variant='outlined'
              name='bjjRang'
              onChange={handleChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={4} sm={2}>
            <TextField
              id='rang'
              type='number'
              label='MMA Sort'
              value={rang}
              helperText='Rang za sortiranje MMA'
              variant='outlined'
              name='rang'
              onChange={handleChange}
              className={style.inputGroup}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          error={errors && errors.name && true}
          id='name'
          label='Ime i Prezime'
          value={name}
          helperText={errors && errors.name ? errors.name : ''}
          variant='outlined'
          name='name'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          error={errors && errors.disciplines && true}
          id='discipline'
          label='Discipline'
          value={disciplines}
          helperText={
            errors && errors.disciplines ? errors.disciplines : 'Format: bjj, mma'
          }
          variant='outlined'
          name='disciplines'
          onChange={handleDisciplineChange}
          className={style.inputGroup}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          id='bjjBelt'
          label='Pojas'
          value={bjjBelt}
          helperText=''
          variant='outlined'
          name='bjjBelt'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          id='category'
          label='Kategorija'
          value={category}
          helperText=''
          variant='outlined'
          name='category'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography className={style.subheader}>MMA Profi</Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              type='number'
              id='mma pro won'
              label='Pobede'
              value={mma.professional.won}
              helperText=''
              variant='outlined'
              name='won'
              onChange={handleMMAProChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              type='number'
              id='mma pro lost'
              label='Porazi'
              value={mma.professional.lost}
              helperText=''
              variant='outlined'
              name='lost'
              onChange={handleMMAProChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              type='number'
              id='mma Pro draw'
              label='N / C'
              value={mma.professional.draw}
              helperText=''
              variant='outlined'
              name='draw'
              onChange={handleMMAProChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id='MMA Pro Results'
              label='Rezultati'
              helperText='Za prelazak na novi red dodati zvezdicu *'
              multiline
              rows='5'
              value={mma.professional.accomplishments}
              variant='outlined'
              name='accomplishments'
              onChange={handleMMAProChange}
              className={style.inputGroup}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography className={style.subheader}>MMA Amaterski</Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              type='number'
              id='mmaAmaWon'
              label='Pobede'
              value={mma.amateur.won}
              helperText=''
              variant='outlined'
              name='won'
              onChange={handleMMAAmaChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              type='number'
              id='mmaLost'
              label='Porazi'
              value={mma.amateur.lost}
              helperText=''
              variant='outlined'
              name='lost'
              onChange={handleMMAAmaChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              type='number'
              id='mmaWon'
              label='N / C'
              value={mma.amateur.draw}
              helperText=''
              variant='outlined'
              name='draw'
              onChange={handleMMAAmaChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id='MMA Ama Results'
              label='Rezultati'
              helperText='Za prelazak na novi red dodati zvezdicu *'
              multiline
              rows='5'
              value={mma.amateur.accomplishments}
              variant='outlined'
              name='accomplishments'
              onChange={handleMMAAmaChange}
              className={style.inputGroup}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography className={style.subheader}>BJJ</Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              type='number'
              id='bjj won'
              label='Pobede'
              value={bjj.won}
              helperText=''
              variant='outlined'
              name='won'
              onChange={handleBJJChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type='number'
              id='bjjLost'
              label='Porazi'
              value={bjj.lost}
              helperText=''
              variant='outlined'
              name='lost'
              onChange={handleBJJChange}
              className={style.inputGroup}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id='BJJ Results'
              label='Rezultati'
              helperText='Za prelazak na novi red dodati zvezdicu *'
              multiline
              rows='5'
              value={bjj.accomplishments}
              variant='outlined'
              name='accomplishments'
              onChange={handleBJJChange}
              className={style.inputGroup}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography className={style.subheader}>Ostali Sportovi</Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id='Ostali Rezultati'
              label='Rezultati'
              helperText='Za prelazak na novi red dodati zvezdicu *'
              multiline
              rows='8'
              value={otherAccomplishments}
              variant='outlined'
              name='otherAccomplishments'
              className={style.inputGroup}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

export default CompetitorForm
