import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import withLoader from '../../components/HOC/withLoader';
import EventPanel from '../../components/ExpPanel/Panels/EventPanel'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'

const Events = ({ events, year, handleRemove }) => (
	<Fragment>
		{events.length ? (
			events.map((event) => (
				<EventPanel
					image={`/images/events/mini/${event.imageMini}`}
					key={event._id}
					title={event.name}
					chip={moment(event.date).format('DD/MM/YYYY')}
					location={event.location}
					editUrl={`/dogadjaj/izmeni/${event._id}`}
					handleRemove={handleRemove}
					id={event._id}
					chipSize
				/>
			))
		) : (
			<Typography>Nema sačuvanih događaja za {year}-u godinu</Typography>
		)}
	</Fragment>
);

const mapStateToProps = ({ events }) => ({
	events: events.events,
	isLoading: events.isLoading
});

export default connect(mapStateToProps)(
	withLoader(Events)
);
