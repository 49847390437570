import React from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Form from './FormContainer'
const useStyles = makeStyles(theme => ({
  inputGroup: {
    width: '100%',
  },
  buttons: {
    margin: '20px 10px 20px 0',
  },
}))

const AcademyForm = ({
  values,
  errors,
  handleChange,
  handleFileChange,
  ...otherProps
}) => {
  const { location, name, address, about, mapUrl, phone, place, email, rang } = values
  const style = useStyles()
  return (
    <Form {...otherProps}>
      <Grid item xs={12} sm={6}>
        <TextField
          error={errors && errors.name && true}
          id='name'
          label='Klub'
          value={name}
          helperText={errors && errors.name ? errors.name : 'Ime kluba'}
          variant='outlined'
          name='name'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextField
          error={errors && errors.location && true}
          id='location'
          label='Mesto'
          value={location}
          helperText={errors && errors.location ? errors.location : 'Grad/Opština'}
          variant='outlined'
          name='location'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>
	  <Grid item xs={4} sm={2}>
          <TextField
            id='rang 123'
            label='Rang'
            value={rang}
            helperText='Za sortiranje...'
            variant='outlined'
            name='rang'
            onChange={handleChange}
            className={style.inputGroup}
          />
        </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='address'
          label='Adresa'
          value={address}
          helperText='Ulica i broj'
          variant='outlined'
          name='address'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='place'
          label='Lokacija'
          value={place}
          helperText='SC Sinergija..'
          variant='outlined'
          name='place'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          id='phone'
          label='Telefon'
          value={phone}
          helperText=''
          variant='outlined'
          name='phone'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='email'
          label='Email'
          value={email}
          helperText=''
          variant='outlined'
          name='email'
          onChange={handleChange}
          className={style.inputGroup}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id='about 123'
          label='O Klubu'
          helperText='Za prelazak na novi red dodati zvezdicu *'
          multiline
          rows='5'
          value={about}
          variant='outlined'
          name='about'
          className={style.inputGroup}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id='mapUrl 123'
          label='Mapa'
          value={mapUrl}
          helperText='Link sa google maps-a'
          variant='outlined'
          name='mapUrl'
          onChange={handleChange}
          className={style.inputGroup}
        />
        <Button variant='contained' component='label' className={style.buttons}>
          Dodaj Raspored
          <input
            type='file'
            style={{ display: 'none' }}
            name='classes'
            onChange={handleFileChange}
          />
        </Button>
        <Button variant='contained' component='label' className={style.buttons}>
          Dodaj Cover Sliku
          <input
            type='file'
            style={{ display: 'none' }}
            name='cover'
            onChange={handleFileChange}
          />
        </Button>
      </Grid>
    </Form>
  )
}

export default AcademyForm
