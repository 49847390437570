import axios from 'axios'
import * as types from '../types'
import jwt_decode from 'jwt-decode'
import setAuthToken from '../../utils/setAuthToken'
import {setErrors, clearErrors} from './error-actions'

export const setCurrentUser = user => ({type: types.SET_CURRENT_USER, payload: user})

const setLoading = (isLoading = true) => ({type: types.SET_AUTH_LOADING, payload: isLoading})

const handleErrorsDispatch = (err, dispatch) => {
	dispatch(setErrors(err.response.data));
	dispatch(setLoading(false))
}

const handleActionBeginDispatch = dispatch => {
	dispatch(setLoading())
	dispatch(clearErrors())
}

export const login = data => async dispatch => {
    handleActionBeginDispatch(dispatch)
    try {
       const res = await axios.post('/api/v1/auth/login', data)
       const { token } = res.data
       localStorage.setItem('jwtToken', token)
       setAuthToken(token.split(' ')[1])
       const decoded = jwt_decode(token)
       dispatch(setCurrentUser(decoded))
    } catch (err) {
        handleErrorsDispatch(err, dispatch)
    }
}

export const logoutUser = () => async dispatch => {
    dispatch(clearErrors())
    await axios.get('/api/v1/auth/logout')
    setAuthToken(false)
    setCurrentUser(null)
    localStorage.removeItem('jwtToken')
    dispatch({type: types.LOGOUT})
    window.location.href = '/'
}
