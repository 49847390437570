import * as types from '../types';
import axios from 'axios';
import { uploadImages } from '../utils';
import {setErrors, clearErrors} from './error-actions'

const setLoading = (isLoading = true) => ({type: types.SET_INSTRUCTORS_LOADING, payload: isLoading})

const handleErrorsDispatch = (err, dispatch) => {
	dispatch(setErrors(err.response.data));
	dispatch(setLoading(false))
}

const handleActionBeginDispatch = dispatch => {
	dispatch(setLoading())
	dispatch(clearErrors())
}

export const fetchInstructors = () => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.get('/api/v1/instructor');
		dispatch({
			type: types.FETCH_INSTRUCTORS_SUCCESS,
			payload: res.data.data.data
		});
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const fetchInstructor = (id) => async (dispatch) => {
	try {
		dispatch({ type: types.FETCH_INSTRUCTOR_START });
		dispatch(clearErrors());
		const res = await axios.get('/api/v1/instructor/' + id);
		dispatch({
			type: types.FETCH_INSTRUCTOR_SUCCESS,
			payload: res.data.data.data
		});
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const addInstructor = (data, formData) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.post('/api/v1/instructor', data);
		const images = await uploadImages(formData, res.data.data.data._id);
		dispatch({
			type: types.ADD_INSTRUCTOR_SUCCESS,
			payload: { ...res.data.data.data, ...images },
			message: `Instruktor ${res.data.data.data.name} je uspešno dodat.`
		});
		setTimeout(() => dispatch({type: types.CLEAR_INSTRUCTORS_MESSAGE}), 5000)
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const editInstructor = (data, formData, id) => async (dispatch) => {
	console.log('start Editing')
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.patch('/api/v1/instructor/' + id, data);
		const images = await uploadImages(formData, id);
		dispatch({
			type: types.EDIT_INSTRUCTOR_SUCCESS,
			payload: { ...res.data.data.data, ...images },
			message: `Instruktor ${res.data.data.data.name} je uspešno izmenjen.`
		});
		setTimeout(() => dispatch({type: types.CLEAR_INSTRUCTORS_MESSAGE}), 5000)
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const deleteInstructor = (id, history) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		await axios.delete('/api/v1/instructor/' + id);
		dispatch({
			type: types.DELETE_INSTRUCTOR_SUCCESS,
			payload: id,
			message: 'Instruktor je uspešno izbrisan.'
		});
		setTimeout(() => {
			dispatch({type: types.CLEAR_INSTRUCTORS_MESSAGE})
			history.push('/instruktori')
		}, 1500)
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const clearInstructor = () => ({ type: types.CLEAR_INSTRUCTOR });
