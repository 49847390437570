import * as types from '../types';

const initialState = {
	isLoading: false,
	isAuthenticated: false,
	currentUser: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.SET_AUTH_LOADING:
			return { ...state, isLoading: action.payload };
		case types.SET_CURRENT_USER:
			return { ...state, currentUser: action.payload, isAuthenticated: true, isLoading: false };
		case types.LOGOUT:
			return { ...state, currentUser: null, isLoading: false };
		default:
			return state;
	}
};
