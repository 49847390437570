import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import {
	Grid,
	Button,
	Box,
	Typography,
	CircularProgress
} from '@material-ui/core';
import SnackBar from '../../components/SnackBar/SnackBar';
import ConfirmDialog from '../../components/Dialog/Dialog'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	actions: {
		margin: '20px 0',
		display: 'flex',
		justifyContent: 'flex-end'
	},
	buttons: {
		marginLeft: '20px',
		width: '85px'
	},
	header: {
		textAlign: 'center'
	},
	progress: {
		color: green[500]
	},
	progressRemove: {
		color: red[500]
	}
}));

const Form = ({
	children,
	handleSubmit,
	handleRemove,
	title,
	message,
	isLoading,
	isEditing,
	errorMessage
}) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(isLoading)
	const [isOpen, setDialogVisibility] = useState(false)

	useEffect(() => {
		setLoading(isLoading)
	}, [isLoading])

	return (
		<Fragment>
			<Box py={4}>
				<Typography variant="h3" mb="20px" className={classes.header}>
					{title}
				</Typography>
			</Box>
			<form>
				<Grid container spacing={3}>
					{children}
					<Grid item sm={12}>
						<Box className={classes.actions}>
							<Button
								variant="contained"
								color="primary"
								component="label"
								className={classes.buttons}
								onClick={handleSubmit}
								disabled={loading}
							>
								{loading ? (
									<CircularProgress size={20} className={classes.progress} />
								) : (
									'Sačuvaj'
								)}
							</Button>
							{isEditing && (
								<Button
									type="button"
									variant="contained"
									color="secondary"
									component="label"
									className={classes.buttons}
									onClick={() => setDialogVisibility(true)}
									disabled={loading}
								>
									{loading ? (
										<CircularProgress size={20} className={classes.progress} />
									) : (
										'Izbriši'
									)}
								</Button>
							)}
						</Box>
					</Grid>
				</Grid>
			</form>
			<SnackBar message={message} />
			<SnackBar message={errorMessage} error/>
			{isOpen && <ConfirmDialog 
				description="Podaci i slike će biti trajno obrisani."
				onConfirm={handleRemove}
				isOpen={isOpen}
				handleVisibility={setDialogVisibility}
			/>}
		</Fragment>
	);
};

export default Form;
