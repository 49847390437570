
import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	label: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
		width: '160px'
	},
	text: {
		fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    container: {
        display: 'flex',
        width:'100%'
    },
    children: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

const ListItem = ({ label, children }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Typography className={classes.label}>{label}</Typography>
			<div className={classes.children}>{children}</div>
		</div>
	);
};

export default ListItem;
