import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  editCompetitor,
  clearCompetitor,
  deleteCompetitor,
  fetchCompetitor,
} from '../../redux/actions/competitors-actions'
import { clearErrors } from '../../redux/actions/error-actions'
import CompetitorForm from '../../components/Forms/CompetitorForm'

const AddCompetitor = ({
  editCompetitor,
  deleteCompetitor,
  clearCompetitor,
  fetchCompetitor,
  message,
  isLoading,
  history,
  errors,
  clearErrors,
  errorMessage,
  match,
  competitor,
}) => {
  const [state, setState] = useState(competitor)
  const [image, setImage] = useState(null)
  console.log(state)

  useEffect(() => {
    fetchCompetitor(match.params.id)
    return () => {
      clearErrors()
      clearCompetitor()
    }
  }, [fetchCompetitor, match.params.id, clearErrors, clearCompetitor])

  useEffect(() => {
    setState(competitor)
  }, [competitor])

  const handleSubmit = async e => {
    e.preventDefault()
    let formData = null
    const data = {
      ...state,
      rang: parseInt(state.rang),
      bjjRang: parseInt(state.bjjRang),
      generalRang: parseInt(state.generalRang),
    }
    delete data.image

    if (image) {
      formData = new FormData()
      formData.append('competitor', image)
    }

    editCompetitor(data, formData, match.params.id, history)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }

  const handleMMAProChange = e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      mma: {
        ...prevState.mma,
        professional: {
          ...prevState.mma.professional,
          [name]: value && !isNaN(value) ? parseInt(value) : value,
        },
      },
    }))
  }

  const handleMMAAmaChange = e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      mma: {
        ...prevState.mma,
        amateur: {
          ...prevState.mma.amateur,
          [name]: value && !isNaN(value) ? parseInt(value) : value,
        },
      },
    }))
  }

  const handleBJJChange = e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      bjj: {
        ...prevState.bjj,
        [name]: value && !isNaN(value) ? parseInt(value) : value,
      },
    }))
  }

  const handleFileChange = e => {
    const { files } = e.target
    setImage(files[0])
  }

  const handleDisciplineChange = e => {
    const { value } = e.target
    const disciplines = value.split(',').map(el => el.trim())

    setState(prevState => ({ ...prevState, disciplines }))
  }

  const handleRemove = () => {
    deleteCompetitor(match.params.id, history)
  }

  return (
    <Fragment>
      {state ? (
        <CompetitorForm
          title={'Izmeni Takmičara'}
          values={state}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleFileChange={handleFileChange}
          handleMMAProChange={handleMMAProChange}
          handleMMAAmaChange={handleMMAAmaChange}
          handleDisciplineChange={handleDisciplineChange}
          handleBJJChange={handleBJJChange}
          handleRemove={handleRemove}
          isLoading={isLoading}
          message={message}
          errors={errors}
          errorMessage={errorMessage}
          isEditing
        />
      ) : (
        'Loading'
      )}
    </Fragment>
  )
}

const mapStateToProps = ({
  competitors: { isLoading, message, competitor },
  errors,
}) => ({
  isLoading,
  message,
  competitor,
  errorMessage: errors && errors.message ? errors.message : null,
  errors: errors && errors.validationErrors ? errors.validationErrors : null,
})

export default connect(mapStateToProps, {
  editCompetitor,
  deleteCompetitor,
  clearCompetitor,
  fetchCompetitor,
  clearErrors,
})(AddCompetitor)
