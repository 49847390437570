import axios from 'axios'

export const replaceArrayItemById = (arr, id, newItem) => {
    const newArr = [...arr]
    const index = arr.findIndex(arrItem => arrItem._id === id)
    newArr.splice(index, 1, newItem)
    return newArr
}

export const uploadImages = async (formData, id) => {
    let data = {}
    if (formData) {
        try {
            formData.set('id', id)
            const res = await axios.post('/api/v1/upload', formData)
            data = res.data.data.data
            
        } catch (err) {
            console.log(err.response)
        }
    }
    return data
}

export const addInstructorToAcademy = (instructor, academies) => {
    const academyIndex = academies.findIndex(aca => aca._id === instructor.atAcademy._id)
    const academiesCopy = [...academies]

    if (academyIndex < 0) {
        return [...academies]
    }

    academiesCopy[academyIndex].instructors.push(instructor)

    return academiesCopy
}

export const removeInstructorFromAcademy = (instructor, academies) => {
    const academyIndex = academies.findIndex(aca => aca._id === instructor.atAcademy._id)
    const academiesCopy = [...academies]

    if (academyIndex < 0) {
        return [...academies]
    }
    const modifiedInstructors = academiesCopy[academyIndex].instructors.filter(instr => instr._id !== instructor._id)
    academiesCopy[academyIndex].instructors = modifiedInstructors
    return academiesCopy
}

export const changeInstructorFromToAcademy = (instructor, academies, academyFrom, academyTo) => {
    
}