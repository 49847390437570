import * as types from '../types';
import { replaceArrayItemById } from '../utils';

const initialState = {
	isLoading: false,
	academies: [],
	message: null,
	academy: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.SET_ACADEMIES_LOADING:
			return { ...state, errors: null, message: null, isLoading: action.payload };
		case types.FETCH_ACADEMIES_SUCCESS:
			return { ...state, academies: action.payload, isLoading: false };
		case types.FETCH_ACADEMY_START:
			return { ...state, academy: null, isLoading: true };
		case types.FETCH_ACADEMY_SUCCESS:
			return { ...state, academy: action.payload, isLoading: false };
		case types.ADD_ACADEMY_SUCCESS:
			return {
				...state,
				academies: [ ...state.academies, action.payload ],
				message: action.message,
				isLoading: false
			};
		case types.EDIT_ACADEMY_SUCCESS:
			return {
				...state,
				academies: replaceArrayItemById(
					state.academies,
					action.payload._id,
					action.payload
				),
				message: action.message,
				academy: action.payload,
				isLoading: false
			};
		case types.DELETE_ACADEMY_SUCCESS:
			return {
				...state,
				academies: state.academies.filter(
					(academy) => academy._id !== action.payload
				),
				academy: null,
				message: action.message,
				isLoading: false
			};
		case types.CLEAR_ACADEMY:
			return { ...state, academy: null };
		case types.CLEAR_ACADEMIES_MESSAGE:
			return { ...state, message: null };
		case types.POST_ADD_INSTRUCTOR:
			return { ...state, instructors: [ ...state.instructors, action.payload ] };
		case types.POST_DELETE_INSTRUCTOR:
			return {
				...state,
				instructors: state.instructors.filter((instr) => instr === 1)
			};
		case types.POST_EDIT_INSTRUCTOR:
			return { ...state };
		default:
			return state;
	}
};
