import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import {
	fetchInstructor,
	clearInstructor,
	editInstructor,
    deleteInstructor
} from '../../redux/actions/instructors-actions';
import { fetchAcademies } from '../../redux/actions/academies-actions';
import { clearErrors } from '../../redux/actions/error-actions';
import InstructorForm from '../../components/Forms/InstructorForm';

const FormInstructor = ({
	academies,
	instructor,
	match,
	fetchInstructor,
	clearInstructor,
	clearErrors,
    deleteInstructor,
    editInstructor,
	message,
	history,
	fetchAcademies,
	errorMessage,
	errors,
	isLoading
}) => {
	const [ state, setState ] = useState(instructor);
	const [ image, setImage ] = useState(null);
	const [ academySelect, setAcademy ] = useState([]);
	const [ academyHasChanged, checkAcademySelect ] = useState(false)

    useEffect(
		() => {
            fetchInstructor(match.params.id);
			return () => {
				clearErrors();
				clearInstructor();
			};
		},
		[ fetchInstructor, match.params.id, clearErrors, clearInstructor ]
	);

    useEffect(
		() => {
			setState(instructor)
			if (instructor) {
				setAcademy(instructor.atAcademy.map(aca => aca._id));
			}
		},
		[ instructor]
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!academySelect || !academySelect.length) {
			return alert(
			  'Lokacija je obavezno polje. Ako nema lokacije, prvo dodaj lokaciju pa onda instruktora.'
			);
		  }
		let formData = null;
		const data = { ...state, atAcademy: academySelect };
		if (academyHasChanged) data.academyHasChanged = academyHasChanged

		delete data.image;

		if (image) {
			formData = new FormData();
			formData.append('instructor', image);
        }
        
		editInstructor(data, formData, match.params.id);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleMMAProChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({
			...prevState,
			mma: {
				...prevState.mma,
				professional: {
					...prevState.mma.professional,
					[name]: value && !isNaN(value) ? parseInt(value) : value
				}
			}
		}));
	};

	const handleMMAAmaChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({
			...prevState,
			mma: {
				...prevState.mma,
				amateur: {
					...prevState.mma.amateur,
					[name]: value && !isNaN(value) ? parseInt(value) : value
				}
			}
		}));
	};

	const handleBJJChange = (e) => {
		const { name, value } = e.target;
		
		setState((prevState) => ({
			...prevState,
			bjj: {
				...prevState.bjj,
				[name]: value && !isNaN(value) ? parseInt(value) : value
			}
		}));
	};

	const handleFileChange = (e) => {
		const { files } = e.target;
		setImage(files[0]);
	};

	const handleAcademyChange = (e) => {
		console.log(e)
		const {value} = e.target
		setAcademy(value);
		checkAcademySelect(true)
	};

	const handleRemove = () => {
		deleteInstructor(match.params.id, history);
	};

	console.log('ACADEMIES SELECTED', academySelect)

	return (
        <Fragment>
		{state ? <InstructorForm
			title={'Izmeni Instruktora'}
			values={{ ...state, academySelect }}
            academies={academies}
			handleSubmit={handleSubmit}
			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleMMAProChange={handleMMAProChange}
			handleMMAAmaChange={handleMMAAmaChange}
			handleAcademyChange={handleAcademyChange}
			handleBJJChange={handleBJJChange}
			handleRemove={handleRemove}
			isLoading={isLoading}
			message={message}
			errors={errors}
			errorMessage={errorMessage}
			isEditing
		/> : 'Loading...'}
        </Fragment>
	);
};

const mapStateToProps = ({
	academies,
	instructors: { isLoading, message, instructor },
	errors
}) => ({
	academies: academies.academies,
	message,
	instructor,
	isLoading: isLoading || academies.isLoading,
	errorMessage: errors && errors.message ? errors.message : null,
	errors: errors && errors.validationErrors ? errors.validationErrors : null
});

export default connect(mapStateToProps, {
	fetchAcademies,
	editInstructor,
	clearInstructor,
	deleteInstructor,
	clearErrors,
	fetchInstructor
})(FormInstructor);
