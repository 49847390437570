import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store'
import { BrowserRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'
import {setCurrentUser, logoutUser} from './redux/actions/auth-actions'
import './index.scss';


if (localStorage.jwtToken) {
	const decoded = jwt_decode(localStorage.jwtToken)
	setAuthToken(localStorage.jwtToken)
	store.dispatch(setCurrentUser(decoded))

	const currentTime = Date.now() / 1000
	
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser())
    }
}

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter basename="/admin">
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
