import React from 'react';
import Form from './FormContainer';
import { Grid, TextField, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	inputGroup: {
		width: '100%'
	},
	buttons: {
		margin: '20px 10px 20px 0'
	}
}));



const AcademyForm = ({values, errors, handleChange, handleFileChange, handleDateChange, ...otherProps}) => {
    const { location, name, about, videos, results, gallery, date } = values;
    const style = useStyles()
	return (
		<Form {...otherProps}>
			<Grid item xs={4}>
				<TextField
					error={errors && errors.name && true}
					id="name"
					label="Naziv Takmičenja"
					value={name}
					helperText={errors && errors.name ? errors.name : ''}
					variant="outlined"
					name="name"
					onChange={handleChange}
					className={style.inputGroup}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					error={errors && errors.location && true}
					id="location"
					label="Mesto"
					value={location}
					helperText={errors && errors.location ? errors.location : "Grad/Opština/Zemlja"}
					variant="outlined"
					name="location"
					onChange={handleChange}
					className={style.inputGroup}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					error={errors && errors.date && true}
					id="date"
					label="Datum"
					value={date}
					helperText={errors && errors.date ? errors.date : "DD/MM/YYYY"}
					variant="outlined"
					name="date"
					onChange={handleDateChange}
					className={style.inputGroup}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					id="about Text"
					label="Opis Takmičenja"
					helperText="Za prelazak na novi red dodati zvezdicu *"
					multiline
					rows="5"
					value={about}
					variant="outlined"
					name="about"
					className={style.inputGroup}
					onChange={handleChange}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					id="results text"
					label="Rezultati"
					helperText="Za prelazak na novi red dodati zvezdicu *"
					multiline
					rows="5"
					value={results}
					variant="outlined"
					name="results"
					className={style.inputGroup}
					onChange={handleChange}
				/>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Tooltip title="Kopirati Link sa youtube-a" placement="top-start">
					<TextField
						id="videos"
						label="Youtube *"
						value={videos}
						helperText="Dodati zvezdicu na kraju linka *"
						variant="outlined"
						name="videos"
						onChange={handleChange}
						className={style.inputGroup}
					/>
				</Tooltip>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Tooltip title="Kopirati Link gde se nalazi galerija" placement="top-start">
					<TextField
						id="galerija"
						label="Galerija *"
						value={gallery}
						helperText="Dodati zvezdicu na kraju linka *"
						variant="outlined"
						name="gallery"
						onChange={handleChange}
						className={style.inputGroup}
					/>
				</Tooltip>
			</Grid>

			<Grid item xs={12} sm={8} md={5}>
				<Grid container>
					<Grid item sm={4}>
						<Tooltip title="250x170" placement="top-start">
							<Button
								variant="contained"
								component="label"
								className={style.buttons}
								size="small"
							>
								Mini Slika
								<input
									type="file"
									style={{ display: 'none' }}
									name="imageMini"
									onChange={handleFileChange}
								/>
							</Button>
						</Tooltip>
					</Grid>
					<Grid item sm={4}>
						<Button
							variant="contained"
							component="label"
							className={style.buttons}
							size="small"
						>
							Cover Slika
							<input
								type="file"
								style={{ display: 'none' }}
								name="imageCover"
								onChange={handleFileChange}
							/>
						</Button>
					</Grid>
					<Grid item sm={4}>
						<Tooltip title="Izaberi više slika" placement="top-start">
							<Button
								variant="contained"
								component="label"
								className={style.buttons}
								size="small"
							>
								Ostale Slike
								<input
									type="file"
									style={{ display: 'none' }}
									multiple="multiple"
									name="imagesOther"
									onChange={handleFileChange}
								/>
							</Button>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>
		</Form>
	);
};

export default AcademyForm
