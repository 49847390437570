import React from 'react'
import { Container } from './styles'
import { connect } from 'react-redux'
import { deleteAcademy } from '../../redux/actions/academies-actions'
import Academies from './Academies'

const AcademiesContainer = ({ academies, isLoading, deleteAcademy, history }) => {
  const handleRemove = id => {
    deleteAcademy(id, history)
  }

  console.log(academies)

  return (
    <Container>
      <Academies
        academies={academies}
        isLoading={isLoading}
        handleRemove={handleRemove}
      />
    </Container>
  )
}

const mapStateToProps = ({ academies }) => ({
  academies: academies.academies.sort((doc1, doc2) => {
	if (doc1.rang === 0 && doc2.rang > 0) return 1
	if (doc2.rang === 0 && doc1.rang > 0) return -1
	if (doc1.rang < doc2.rang) return -1
  }),
  isLoading: academies.isLoading,
})

export default connect(mapStateToProps, { deleteAcademy })(AcademiesContainer)
