import React from 'react'
import ExpansionPanel from '../ExpansionPanel'
import DetailsRow from '../DetailsRow'
import {Typography} from '@material-ui/core'


const EventPanel = ({location, ...otherProps}) => {

    return (
        <ExpansionPanel {...otherProps}>
            <DetailsRow label="Lokacija">
                <Typography>{location}</Typography>
            </DetailsRow>
        </ExpansionPanel>
    )
}

export default EventPanel