import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '../Menu/Menu.jsx'
import { logoutUser } from '../../redux/actions/auth-actions'
import {connect} from 'react-redux'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
  }
}));

const options = [
  {option:'Takmičara', to: '/takmicar/dodaj'},
  {option:'Klub', to: '/klub/dodaj'},
  {option:'Instruktora', to: '/instruktor/dodaj'},
  {option:'Događaj', to: '/dogadjaj/dodaj'}
]
const ButtonAppBar = ({logoutUser}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
      <Toolbar>
      <Typography variant="h6" className={classes.title}>
            Dashboard
        </Typography>
        <Menu title="Dodaj" options={options} className={classes.menuButton}/>
            <Button color="inherit" variant="outlined" onClick={() => logoutUser()}>Logout</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default connect(null, {logoutUser})(ButtonAppBar)