import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addEvent } from '../../redux/actions/events-actions';
import { clearErrors } from '../../redux/actions/error-actions';
import EventForm from '../../components/Forms/EventForm';
import moment from 'moment';


const initialState = {
	location: '',
	name: '',
	about: '',
	imageCover: '',
	imageMini: '',
	images: [],
	date: '',
	videos: '',
	results: '',
	gallery: ''
};

const AddEvent = ({
	addEvent,
	message,
	isLoading,
	history,
	errors,
	clearErrors,
	errorMessage,
	events
}) => {
	
	const [ state, setState ] = useState(initialState);
	const [ images, setImages ] = useState({
		imageCover: null,
		imageMini: null,
		imagesOther: null
	});
    const [ date, setDate ] = useState('');
    
	useEffect(
		() => {
			return () => {
				if (errors) {
					clearErrors();
				}
			};
		},
		[ clearErrors, errors ]
	);

	useEffect(
		() => {
			
				setState(initialState);
				setImages({
                    imageCover: null,
                    imageMini: null,
                    imagesOther: null
                })
                setDate('')
			
		},
		[ events.length ]
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let formData = null;
		const data = { ...state, date: moment(date, 'DD/MM/YYYY') };
		delete data.imageCover;
		delete data.imageMini;
		delete data.images;

		if (images.imageCover || images.imageMini || images.imagesOther) {
			const { imageCover, imageMini, imagesOther } = images;
			formData = new FormData();
			if (imageMini) formData.append('eventMini', imageMini[0]);
			if (imageCover) formData.append('eventCover', imageCover[0]);
			if (imagesOther) {
				for (let img of imagesOther) {
					formData.append('eventOther', img);
				}
			}
		}

		
	    addEvent(data, formData, history);
		

		setImages({
			imageCover: null,
			imageMini: null,
			imagesOther: null
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleDateChange = (e) => {
		const { value } = e.target;
		setDate(value);
	};

	const handleFileChange = (e) => {
		const { files, name } = e.target;
		setImages((prevState) => ({
			...prevState,
			[name]: files
		}));
	};

	
	return (
		<EventForm
			title={'Dodaj Događaj'}
            values={{...state, date}}
            handleSubmit={handleSubmit}
			handleChange={handleChange}
            handleFileChange={handleFileChange}
            handleDateChange={handleDateChange}
			isLoading={isLoading}
			message={message}
			errors={errors}
            errorMessage={errorMessage}
		/>
	);
};

const mapStateToProps = ({
	events: { isLoading, message, events },
	errors
}) => ({
	isLoading,
	message,
	events,
	errorMessage: errors && errors.message ? errors.message : null,
	errors: errors && errors.validationErrors ? errors.validationErrors : null
});

export default connect(mapStateToProps, {
	addEvent,
	clearErrors
})(AddEvent);
