import React from 'react';
import { LinearProgress } from '@material-ui/core'


const WithLoader = Component => ({ isLoading, ...props }) => {
    if (!isLoading) return (<Component {...props} />);
    return (
    <div style={{width:'100%', height:'100%'}}>
        <LinearProgress />
    </div>
    );
}


export default WithLoader