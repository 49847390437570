import React from 'react';
import {Menu, Button, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
	link: {
	  width:'100px',
	  padding:'7px 0',
	  transition: 'all .2s',
	  background: 'transparent',
	  '&:hover': {
		background: 'linear-gradient(315deg, rgba(220, 220, 210, 0.3), rgba(200, 200, 200, 0.3))'
	  }
	},
	typo: {
		fontSize: theme.typography.pxToRem(14),
		color: '#000',
		textAlign:'left',
		paddingLeft:'10px'
	},
	menuButton: {
	  marginRight: theme.spacing(2)
	},
	title: {
	  flexGrow: 1,
	}
  }));

  
  

const MenuLink = styled(Link)`
	display:block;
	padding:1rem 0;
	text-align:center;
`

const SimpleMenu = ({ history, title, options, ...otherProps }) => {
	const [ anchorEl, setAnchorEl ] = React.useState(null);
	const classes = useStyles()

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (e) => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				aria-controls="fade-menu"
				aria-haspopup="true"
				onClick={handleClick}
				variant="outlined"
				color="inherit"
				{...otherProps}
			>
				{title}
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{options.map((opt, i) => (
					
						<MenuLink key={i} to={opt.to} className={classes.link} onClick={handleClose}>
						
						<Typography className={classes.typo}>{opt.option}</Typography>
						</MenuLink>
					
				))}
			</Menu>
		</div>
	);
};

export default SimpleMenu;
