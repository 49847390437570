import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { addInstructor } from '../../redux/actions/instructors-actions';
import { fetchAcademies } from '../../redux/actions/academies-actions';
import { clearErrors } from '../../redux/actions/error-actions';
import InstructorForm from '../../components/Forms/InstructorForm';

const initialState = {
  name: '',
  disciplines: '',
  bjjBelt: '',
  image: '',
  about: '',
  location: '',
  mma: {
    professional: {
      won: 0,
      lost: 0,
      draw: 0,
      accomplishments: ''
    },
    amateur: {
      won: 0,
      lost: 0,
      draw: 0,
      accomplishments: ''
    }
  },
  bjj: {
    won: 0,
    lost: 0,
    accomplishments: ''
  },
  coachingAccomplishments: '',
  other: '',
  martialArts: '',
  atAcademy: [],
  rang: 0
};

const AddInstructor = ({
  academies,
  addInstructor,
  clearErrors,
  message,
  history,
  instructors,
  errorMessage,
  errors,
  isLoading
}) => {
  const [state, setState] = useState(initialState);
  const [image, setImage] = useState(null);
  const [academySelect, setAcademy] = useState([]);

  useEffect(() => {
    return () => {
      if (errors) {
        clearErrors();
      }
    };
  }, [clearErrors, errors]);

  useEffect(() => {
    setState(initialState);
    setImage(null);
    setAcademy([]);
  }, [instructors.length]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!academySelect || !academySelect.length) {
      return alert(
        'Lokacija je obavezno polje. Ako nema lokacije, prvo dodaj lokaciju pa onda instruktora.'
      );
    }
    let formData = null;
    const data = { ...state, atAcademy: academySelect };
    delete data.image;

    if (image) {
      formData = new FormData();
      formData.append('instructor', image);
    }

    addInstructor(data, formData, history);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleMMAProChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      mma: {
        ...prevState.mma,
        professional: {
          ...prevState.mma.professional,
          [name]: value && !isNaN(value) ? parseInt(value) : value
        }
      }
    }));
  };

  const handleMMAAmaChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      mma: {
        ...prevState.mma,
        amateur: {
          ...prevState.mma.amateur,
          [name]: value && !isNaN(value) ? parseInt(value) : value
        }
      }
    }));
  };

  const handleBJJChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      bjj: {
        ...prevState.bjj,
        [name]: value && !isNaN(value) ? parseInt(value) : value
      }
    }));
  };

  const handleFileChange = e => {
    const { files } = e.target;
    setImage(files[0]);
  };

  const handleAcademyChange = e => {
    const { value } = e.target;

    setAcademy(value);
  };

  console.log(academySelect);

  return (
    <Fragment>
      {academies.length > 0 && (
        <InstructorForm
          title={'Dodaj Instruktora'}
          values={{ ...state, academySelect }}
          academies={academies}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleFileChange={handleFileChange}
          handleMMAProChange={handleMMAProChange}
          handleMMAAmaChange={handleMMAAmaChange}
          handleAcademyChange={handleAcademyChange}
          handleBJJChange={handleBJJChange}
          isLoading={isLoading}
          message={message}
          errors={errors}
          errorMessage={errorMessage}
        />
      )}
      {!isLoading &&
        academies.length === 0 &&
        'Pre dodavanja instruktora mora da postoji minimum jedan klub u kom može da bude instruktor.'}
    </Fragment>
  );
};

const mapStateToProps = ({
  academies,
  instructors: { isLoading, message, instructors },
  errors
}) => ({
  academies: academies.academies,
  message,
  instructors,
  isLoading: isLoading || academies.isLoading,
  errorMessage: errors && errors.message ? errors.message : null,
  errors: errors && errors.validationErrors ? errors.validationErrors : null
});

export default connect(mapStateToProps, {
  fetchAcademies,
  addInstructor,
  clearErrors
})(AddInstructor);
