import { combineReducers } from 'redux'
import academiesReducer from './academies-reducer'
import instructorsReducer from './instructors-reducer'
import competitorsReducer from './competitors-reducer'
import eventsReducer from './events-reducer'
import authReducer from './auth-reducer'
import errorsReducer from './errors-reducer'


export default combineReducers({
    auth: authReducer,
    academies: academiesReducer,
    competitors: competitorsReducer,
    events: eventsReducer,
    instructors: instructorsReducer,
    errors: errorsReducer
})