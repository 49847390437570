import React from 'react';
import ExpansionPanel from '../ExpansionPanel';
import DetailsRow from '../DetailsRow';
import { Typography } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	won: {
		color: green[500]
	},
	lost: {
		color: red[500]
	},
	draw: {
		color: grey[300]
	}
}));

const CompetitorPanel = ({ bjjBelt, mma, bjj, ...otherProps }) => {
	const classes = useStyles();

	const { amateur, professional } = mma;

	const bjjTotal = bjj.won + bjj.lost;
	const mmaAmaTotal = amateur.won + amateur.lost + amateur.draw;
	const mmaProTotal = professional.won + professional.lost + professional.draw;

	return (
		<ExpansionPanel {...otherProps}>
			<DetailsRow label="Pojas">
				<Typography>{bjjBelt}</Typography>
			</DetailsRow>
			{mmaProTotal > 0 && (
				<DetailsRow label="MMA Profi">
					<Typography>
						<span>{mmaProTotal}</span>/
						<span className={classes.won}>{professional.won}</span>/
						<span className={classes.lost}>{professional.lost}</span>/
						<span className={classes.draw}>{professional.draw}</span>
					</Typography>
				</DetailsRow>
			)}
			{mmaAmaTotal > 0 && (
				<DetailsRow label="MMA Amaterski">
					<Typography>
						<span>{mmaAmaTotal}</span>/
						<span className={classes.won}>{amateur.won}</span>/
						<span className={classes.lost}>{amateur.lost}</span>/
						<span className={classes.draw}>{amateur.draw}</span>
					</Typography>
				</DetailsRow>
			)}
			{bjjTotal > 0 && (
				<DetailsRow label="BJJ">
					<Typography>
						<span>{bjjTotal}</span>/
						<span className={classes.won}>{bjj.won}</span>/
						<span className={classes.lost}>{bjj.lost}</span>
					</Typography>
				</DetailsRow>
			)}
		</ExpansionPanel>
	);
};

export default CompetitorPanel;
