// ============ ACADEMIES ============ //
export const FETCH_ACADEMIES_SUCCESS = 'FETCH_ACADEMIES_SUCCESS'
export const FETCH_ACADEMY_SUCCESS = 'FETCH_ACADEMY_SUCCESS'
export const FETCH_ACADEMY_START = 'FETCH_ACADEMY_START'
export const ADD_ACADEMY_SUCCESS = 'ADD_ACADEMY_SUCCESS'
export const EDIT_ACADEMY_SUCCESS = 'EDIT_ACADEMY_SUCCESS'
export const DELETE_ACADEMY_SUCCESS = 'DELETE_ACADEMY_SUCCESS'

export const SET_ACADEMIES_LOADING = 'SET_ACADEMIES_LOADING'
export const SET_ACADEMIES_ERRORS = 'SET_ACADEMIES_ERRORS'
export const CLEAR_ACADEMY = 'CLEAR_ACADEMY'
export const CLEAR_ACADEMIES_MESSAGE = 'CLEAR_ACADEMIES_MESSAGE'


// ============ COMPETITORS ============ //
export const FETCH_COMPETITORS_SUCCESS = 'FETCH_COMPETITORS_SUCCESS'
export const FETCH_COMPETITOR_START = 'FETCH_COMPETITOR_START'
export const FETCH_COMPETITOR_SUCCESS = 'FETCH_COMPETITOR_SUCCESS'
export const ADD_COMPETITOR_SUCCESS = 'ADD_COMPETITOR_SUCCESS'
export const EDIT_COMPETITOR_SUCCESS = 'EDIT_COMPETITOR_SUCCESS'
export const DELETE_COMPETITOR_SUCCESS = 'DELETE_COMPETITOR_SUCCESS'

export const SET_COMPETITORS_LOADING = 'SET_COMPETITORS_LOADING'
export const SET_COMPETITORS_ERRORS = 'SET_COMPETITORS_ERRORS'
export const CLEAR_COMPETITOR = 'CLEAR_COMPETITOR'
export const CLEAR_COMPETITORS_MESSAGE = 'CLEAR_COMPETITORS_MESSAGE'


// ============ EVENTS ============ //
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENT_START = 'FETCH_EVENT_START'
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS'
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS'
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'

export const SET_EVENTS_LOADING = 'SET_EVENTS_LOADING'
export const SET_EVENTS_ERRORS = 'SET_EVENTS_ERRORS'
export const CLEAR_EVENT = 'CLEAR_EVENT'
export const CLEAR_EVENTS_MESSAGE = 'CLEAR_EVENTS_MESSAGE'

// ============ INSTRUCTORS ============ //
export const FETCH_INSTRUCTORS_SUCCESS = 'FETCH_INSTRUCTORS_SUCCESS'
export const FETCH_INSTRUCTOR_START = 'FETCH_INSTRUCTOR_START'
export const FETCH_INSTRUCTOR_SUCCESS = 'FETCH_INSTRUCTOR_SUCCESS'
export const ADD_INSTRUCTOR_SUCCESS = 'ADD_INSTRUCTOR_SUCCESS'
export const EDIT_INSTRUCTOR_SUCCESS = 'EDIT_INSTRUCTOR_SUCCESS'
export const DELETE_INSTRUCTOR_SUCCESS = 'DELETE_INSTRUCTOR_SUCCESS'
export const POST_ADD_INSTRUCTOR = 'POST_ADD_INSTRUCTOR'
export const POST_EDIT_INSTRUCTOR = 'POST_EDIT_INSTRUCTOR'
export const POST_DELETE_INSTRUCTOR = 'POST_DELETE_INSTRUCTOR'

export const SET_INSTRUCTORS_LOADING = 'SET_INSTRUCTORS_LOADING'
export const SET_INSTRUCTORS_ERRORS = 'SET_INSTRUCTORS_ERRORS'
export const CLEAR_INSTRUCTOR = 'CLEAR_INSTRUCTOR'
export const CLEAR_INSTRUCTORS_MESSAGE = 'CLEAR_INSTRUCTORS_MESSAGE'

// ============ AUTH ============ //

export const SET_AUTH_LOADING = 'SET_APP_LOADING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const LOGOUT = 'LOGOUT'

// ============ ERRORS ============ //
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SET_ERRORS = 'SET_ERRORS'


