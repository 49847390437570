import React, { Fragment } from 'react'
import withLoader from '../../components/HOC/withLoader'
import AcademyPanel from '../../components/ExpPanel/Panels/AcademyPanel'
import Typography from '@material-ui/core/Typography'

const Academies = ({ academies, handleRemove }) => (
  <Fragment>
    {academies.length ? (
      academies.map(academy => (
        <AcademyPanel
          image={`/images/academies/${academy.cover}`}
          key={academy._id}
          title={academy.name}
          chip={academy.location}
          editUrl={`/klub/izmeni/${academy._id}`}
          handleRemove={handleRemove}
		  id={academy._id}
		  generalRang={academy.rang}
		  {...academy}
		  rang={null}
        />
      ))
    ) : (
      <Typography>Nema sačuvanih klubova</Typography>
    )}
  </Fragment>
)

export default withLoader(Academies)
