import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import {
	editAcademy,
	clearAcademy,
    fetchAcademy,
    deleteAcademy
} from '../../redux/actions/academies-actions';
import { clearErrors } from '../../redux/actions/error-actions';
import AcademyForm from '../../components/Forms/AcademyForm';

const EditAcademy = ({
	editAcademy,
	clearAcademy,
    fetchAcademy,
    deleteAcademy,
	academy,
	message,
	isLoading,
	history,
	errors,
	clearErrors,
	errorMessage,
	match
}) => {
	const [ state, setState ] = useState(null);
	const [ images, setImages ] = useState({ cover: null, classes: null });

	useEffect(() => {
        fetchAcademy(match.params.id);
        return () => {
            clearErrors();
            clearAcademy();
        }
	}, [fetchAcademy, match.params.id, clearErrors, clearAcademy]);

	useEffect(
		() => {
			setState(academy);
		},
		[ academy ]
	);


	const handleSubmit = (e) => {
		e.preventDefault();
		let formData = null;
		const { cover, classes } = images;
		const data = { ...state };
		delete data.classes;
		delete data.cover;

		if (classes || cover) {
			formData = new FormData();
			if (classes) formData.append('academyClasses', classes);
			if (cover) formData.append('academyCover', cover);
		}

		editAcademy(data, formData, match.params.id, history);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleFileChange = (e) => {
		const { files, name } = e.target;
		setImages((prevState) => ({ ...prevState, [name]: files[0] }));
    };
    
    const handleRemove = e => {
		deleteAcademy(match.params.id, history)
	}

	return (
		<Fragment>
			{state ? (
				<AcademyForm
					title={'Izmeni Klub'}
					values={state}
					handleSubmit={handleSubmit}
					handleChange={handleChange}
					handleFileChange={handleFileChange}
					isLoading={isLoading}
					message={message}
					errors={errors}
					errorMessage={errorMessage}
                    handleRemove={handleRemove}
                    isEditing
				/>
			) : (
				<p>Loading...</p>
			)}
		</Fragment>
	);
};

const mapStateToProps = ({
	academies: { isLoading, message, academy },
	errors
}) => ({
	isLoading,
	message,
	academy,
	errorMessage: errors && errors.message ? errors.message : null,
	errors: errors && errors.validationErrors ? errors.validationErrors : null
});

export default connect(mapStateToProps, {
	editAcademy,
	fetchAcademy,
    clearAcademy,
    deleteAcademy,
	clearErrors
})(EditAcademy);
