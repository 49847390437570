import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchAcademies } from './redux/actions/academies-actions';
import { fetchInstructors } from './redux/actions/instructors-actions';
import { fetchCompetitors } from './redux/actions/competitors-actions';

import { Container, Box, LinearProgress } from '@material-ui/core';
import Navigation from './components/Navigation/Navigation';
import TabPanel from './components/TabPanel/TabPanel';
import Dashboard from './pages/Dashboard/Dashboard';
import Instructors from './pages/Instructors';
import Events from './pages/Events';
import Academies from './pages/Academies';
import Competitors from './pages/Competitors';
import SignInSide from './pages/Authentication/SignInSide';
import AddAcademy from './pages/Academies/AddAcademy';
import EditAcademy from './pages/Academies/EditAcademy';
import AddCompetitor from './pages/Competitors/AddCompetitor';
import EditCompetitor from './pages/Competitors/EditCompetitor';
import AddInstructor from './pages/Instructors/AddInstructor';
import EditInstructor from './pages/Instructors/EditInstructor';
import AddEvent from './pages/Events/AddEvent';
import EditEvent from './pages/Events/EditEvent';

class App extends Component {
	state = {
		isAuthenticated: false
	};

	componentDidMount() {
		const {fetchInstructors, fetchAcademies, fetchCompetitors} = this.props
		fetchInstructors()
		fetchAcademies()
		fetchCompetitors()
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location !== prevProps.location) {
			if (this.props.location.pathname.startsWith('/forma')) {
				this.setState({ route: '/forma' });
			} else if (!this.props.location.pathname.startsWith('/forma')) {
				this.setState({ route: '' });
			}
		}
	}


	render() {
		const tabsOpts = [
			{ label: 'Dashboard', value: '/' },
			{ label: 'Klubovi', value: '/klubovi' },
			{ label: 'Insruktori', value: '/instruktori' },
			{ label: 'Takmičari', value: '/takmicari' },
			{ label: 'Događaji', value: '/dogadjaji' }
		];

		const { isLoading, isAuthenticated } = this.props;

		return (
			<div className="app">
				{isAuthenticated && <Navigation />}
				{isAuthenticated && (
					<TabPanel
						tabs={tabsOpts}
						route={this.state.route}
						backUrl={this.state.backUrl}
					/>
				)}

				<Container>
					{isLoading &&
					isAuthenticated && (
						<div
							style={{
								width: '90%',
								maxWidth: '800px',
								margin: '0 auto',
								height: '100%',
								paddingTop: '40px'
							}}
						>
							<LinearProgress />
						</div>
					)}

					{!isAuthenticated && <SignInSide />}
					{isAuthenticated &&
					!isLoading && (
						<Box py={5}>
							<Switch>
								<Route exact path="/" component={Dashboard} />
								<Route exact path="/instruktori" component={Instructors} />
								<Route exact path="/instruktor/dodaj" component={AddInstructor} />
								<Route exact path="/instruktor/izmeni/:id" component={EditInstructor} />
								<Route exact path="/klubovi" component={Academies} />
								<Route exact path="/klub/dodaj" component={AddAcademy} />
								<Route exact path="/klub/izmeni/:id" component={EditAcademy} />
								<Route exact path="/takmicari" component={Competitors} />
								<Route exact path="/takmicar/dodaj" component={AddCompetitor} />
								<Route exact path="/takmicar/izmeni/:id" component={EditCompetitor} />
								<Route exact path="/dogadjaji" component={Events} />
								<Route exact path="/dogadjaj/dodaj" component={AddEvent} />
								<Route exact path="/dogadjaj/izmeni/:id" component={EditEvent} />
							</Switch>
						</Box>
					)}
				</Container>
			</div>
		);
	}
}

const mapStateToProps = ({ auth }) => ({
	isLoading: auth.isLoading,
	isAuthenticated: auth.isAuthenticated
});

export default connect(mapStateToProps, {fetchAcademies, fetchCompetitors, fetchInstructors})(withRouter(App));
