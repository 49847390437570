import * as types from '../types';
import axios from 'axios';
import { uploadImages } from '../utils';
import {setErrors, clearErrors} from './error-actions'

const setLoading = (isLoading = true) => ({type: types.SET_EVENTS_LOADING, payload: isLoading})

const handleErrorsDispatch = (err, dispatch) => {
	dispatch(setErrors(err.response.data));
	dispatch(setLoading(false))
}

const handleActionBeginDispatch = dispatch => {
	dispatch(setLoading())
	dispatch(clearErrors())
}

export const clearEvent = () => ({ type: types.CLEAR_EVENT });

export const fetchEvents = (year) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.get('/api/v1/event/?eventYear=' + year);
		dispatch({ type: types.FETCH_EVENTS_SUCCESS, payload: res.data.data.data });
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const fetchEvent = id => async (dispatch) => {
	try {
		dispatch({ type: types.FETCH_EVENT_START });
		dispatch(clearErrors())
		const res = await axios.get('/api/v1/event/' + id);
		dispatch({ type: types.FETCH_EVENT_SUCCESS, payload: res.data.data.data });
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const addEvent = (data, formData) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.post('/api/v1/event', data);
		const images = await uploadImages(formData, res.data.data.data._id);
		dispatch({
			type: types.ADD_EVENT_SUCCESS,
			payload: { ...res.data.data.data, ...images },
			message: `Događaj ${res.data.data.data.name} je uspešno dodat.`
		});
		setTimeout(() => dispatch({ type: types.CLEAR_EVENTS_MESSAGE }), 5000);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const editEvent = (data, formData, id) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.patch('/api/v1/event/' + id, data);
		const images = await uploadImages(formData, id);
		console.log(res.data.data.data)
		dispatch({
			type: types.EDIT_EVENT_SUCCESS,
			payload: { ...res.data.data.data, ...images },
			message: `Događaj ${res.data.data.data.name} je uspešno izmenjen.`
		});
		setTimeout(() => dispatch({ type: types.CLEAR_EVENTS_MESSAGE }), 5000);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const deleteEvent = (id, history) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		await axios.delete('/api/v1/event/' + id);
		dispatch({
			type: types.DELETE_EVENT_SUCCESS,
			payload: id,
			message: 'Događaj je uspešno izbrisan.'
		});
		setTimeout(() => {
			dispatch({ type: types.CLEAR_EVENTS_MESSAGE })
			history.push('/dogadjaji')
		}, 1500);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};


