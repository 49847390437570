import styled from 'styled-components'

export const Container = styled('div')`
    max-width:600px;
    margin: 0 auto;
`
export const SelectWrapper = styled('div')`
    width:100%;
    display:flex;
    justify-content:center;
    padding:2rem 0;
`

