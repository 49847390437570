import React, { useState } from 'react';
import { Container, SelectWrapper } from './styles';
import { connect } from 'react-redux';
import { fetchEvents, deleteEvent } from '../../redux/actions/events-actions'
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import getAllYears from './utils';

import Events from './Events'

const useStyles = makeStyles((theme) => ({
	inputGroup: {
		width: '100%'
	},
	buttons: {
		margin: '20px 10px 20px 0'
	},
	subheader: {
		marginBottom: '15px'
	},
	formControl: {
		width: '100%'
	}
}));

const EventsContainer = ({ events, fetchEvents, deleteEvent, history  }) => {
	const style = useStyles();
	const allYears = getAllYears();
	const [ year, setYear ] = useState(allYears[1].value);
	const inputLabel = React.useRef(null);

	const [ labelWidth, setLabelWidth ] = React.useState(0);
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
		fetchEvents(year)
	}, [year, fetchEvents]);

	const handleChange = (e) => {
		setYear(e.target.value);
	};

	const handleRemove = (id) => {
		deleteEvent(id, history )	
	}

	return (
		<Container>
			<SelectWrapper>
				<FormControl variant="outlined" className={style.formControl}>
					<InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
						Izaberi Godinu
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={year}
						onChange={handleChange}
						labelWidth={labelWidth}
						name="years"
					>
						{allYears.map((y, i) => (
							<MenuItem key={i} value={y.value}>
								{y.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</SelectWrapper>

			<Events year={year} handleRemove={handleRemove} />
		</Container>
	);
};

const mapStateToProps = ({events}) => ({
	events: events.events
})
export default connect(mapStateToProps, {fetchEvents, deleteEvent})(EventsContainer);
