import * as types from '../types';
import axios from 'axios';
import { uploadImages } from '../utils';
import {setErrors, clearErrors} from './error-actions'

const setLoading = (isLoading = true) => ({type: types.SET_COMPETITORS_LOADING, payload: isLoading})

const handleErrorsDispatch = (err, dispatch) => {
	dispatch(setErrors(err.response.data));
	dispatch(setLoading(false))
}

const handleActionBeginDispatch = dispatch => {
	dispatch(setLoading())
	dispatch(clearErrors())
}

export const clearCompetitor = () => ({ type: types.CLEAR_COMPETITOR });

export const fetchCompetitors = () => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.get('/api/v1/competitor');
		dispatch({
			type: types.FETCH_COMPETITORS_SUCCESS,
			payload: res.data.data.data
		});
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const fetchCompetitor = (id) => async (dispatch) => {
	try {
		dispatch({ type: types.FETCH_COMPETITOR_START });
		dispatch(clearErrors())
		const res = await axios.get('/api/v1/competitor/' + id);
		dispatch({
			type: types.FETCH_COMPETITOR_SUCCESS,
			payload: res.data.data.data
		});
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const addCompetitor = (data, formData) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.post('/api/v1/competitor', data);
		const images = await uploadImages(formData, res.data.data.data._id);
		dispatch({
			type: types.ADD_COMPETITOR_SUCCESS,
			payload: { ...res.data.data.data, ...images },
			message: `Takmičar ${res.data.data.data.name} je uspešno dodat.`
		});
		setTimeout(() => dispatch({ type: types.CLEAR_COMPETITORS_MESSAGE }), 5000);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const editCompetitor = (data, formData, id) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		const res = await axios.patch('/api/v1/competitor/' + id, data);
		const images = await uploadImages(formData, id);
		dispatch({
			type: types.EDIT_COMPETITOR_SUCCESS,
			payload: { ...res.data.data.data, ...images },
			message: `Takmičar ${res.data.data.data.name} je uspešno izmenjen.`
		});
		setTimeout(() => dispatch({ type: types.CLEAR_COMPETITORS_MESSAGE }), 5000);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};

export const deleteCompetitor = (id, history) => async (dispatch) => {
	try {
		handleActionBeginDispatch(dispatch)
		await axios.delete('/api/v1/competitor/' + id);
		dispatch({
			type: types.DELETE_COMPETITOR_SUCCESS,
			payload: id,
			message: 'Takmičar je uspešno izbrisan.'
		});
		setTimeout(() => {
			dispatch({ type: types.CLEAR_COMPETITORS_MESSAGE })
			history.push('/takmicari')
		}, 1500);
	} catch (err) {
		handleErrorsDispatch(err, dispatch)
	}
};


