import * as types from '../types';
import { replaceArrayItemById } from '../utils';

const initialState = {
	isLoading: false,
	events: [],
	message: null,
	event: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.SET_EVENTS_LOADING:
			return { ...state, errors: {}, isLoading: action.payload };
		case types.FETCH_EVENTS_SUCCESS:
			return { ...state, events: action.payload, isLoading: false };
		case types.FETCH_EVENT_START:
			return { ...state, event: null, message: null, isLoading: true };
		case types.FETCH_EVENT_SUCCESS:
			return { ...state, event: action.payload, isLoading: false };
		case types.ADD_EVENT_SUCCESS:
			return {
				...state,
				events: [ ...state.events, action.payload ],
				message: action.message,
				isLoading: false
			};
		case types.EDIT_EVENT_SUCCESS:
			return {
				...state,
				events: replaceArrayItemById(
					state.events,
					action.payload._id,
					action.payload
				),
				event: action.payload,
				message: action.message,
				isLoading: false
			};
		case types.DELETE_EVENT_SUCCESS:
			return {
				...state,
				events: state.events.filter((ev) => ev._id !== action.payload),
				message: action.message,
				event:null,
				isLoading: false
			};
		case types.CLEAR_EVENT:
			return { ...state, event: null };
		case types.CLEAR_EVENTS_MESSAGE:
			return { ...state, message: null };
		default:
			return state;
	}
};
