import React, { Fragment } from 'react';
import withLoader from '../../components/HOC/withLoader';
import CompetitorPanel from '../../components/ExpPanel/Panels/CompetitorPanel'
import Typography from '@material-ui/core/Typography'

const Competitors = ({ competitors, handleRemove }) => (
	<Fragment>
		{
				competitors.length ? 
				competitors.map((competitor) => (
				<CompetitorPanel
					{...competitor}
					image={`/images/competitors/${competitor.image}`}
					key={competitor._id}
					title={competitor.name}
					chip={competitor.disciplines.join('/')}
					chipSize
					editUrl={`/takmicar/izmeni/${competitor._id}`}
					handleRemove={handleRemove}
					id={competitor._id}
					rang={competitor.rang ? competitor.rang : '--'}
					bjjRang={competitor.bjjRang ? competitor.bjjRang : '--'}
					generalRang={competitor.generalRang ? competitor.generalRang : '--'}

				/>
				))
				:
				<Typography>Nema sačuvanih takmičara</Typography>
			}
	</Fragment>
);



export default withLoader(Competitors)

