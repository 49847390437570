import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import {
	editEvent,
	fetchEvent,
	clearEvent,
	deleteEvent
} from '../../redux/actions/events-actions';
import { clearErrors } from '../../redux/actions/error-actions';
import EventForm from '../../components/Forms/EventForm';
import moment from 'moment';

const AddEvent = ({
	deleteEvent,
	editEvent,
	fetchEvent,
	clearEvent,
	message,
	isLoading,
	history,
	errors,
	clearErrors,
	errorMessage,
	match,
	event
}) => {
	const [ state, setState ] = useState(event);
	const [ images, setImages ] = useState({
		imageCover: null,
		imageMini: null,
		imagesOther: null
	});
	const [ date, setDate ] = useState('');

	useEffect(
		() => {
			fetchEvent(match.params.id);
			return () => {
				clearErrors();
				clearEvent();
			};
		},
		[ fetchEvent, match.params.id, clearErrors, clearEvent ]
	);

	useEffect(
		() => {
			setState(event);
			if (event) setDate(moment(event.date).format('DD/MM/YYYY'));
		},
		[ event ]
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let formData = null;
		const data = { ...state, date: moment(date, 'DD/MM/YYYY') };
		delete data.imageCover;
		delete data.imageMini;
		delete data.images;

		if (images.imageCover || images.imageMini || images.imagesOther) {
			const { imageCover, imageMini, imagesOther } = images;
			formData = new FormData();
			if (imageMini) formData.append('eventMini', imageMini[0]);
			if (imageCover) formData.append('eventCover', imageCover[0]);
			if (imagesOther) {
				for (let img of imagesOther) {
					formData.append('eventOther', img);
				}
			}
		}

		editEvent(data, formData, match.params.id, history);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleDateChange = (e) => {
		const { value } = e.target;
		setDate(value);
	};

	const handleFileChange = (e) => {
		const { files, name } = e.target;
		setImages((prevState) => ({
			...prevState,
			[name]: files
		}));
	};

	const handleRemove = (e) => {
		e.preventDefault();
		deleteEvent(match.params.id, history);
	};

	return (
		<Fragment>
			{state ? (
				<EventForm
					title={'Izmeni Događaj'}
					values={{ ...state, date }}
					handleSubmit={handleSubmit}
					handleChange={handleChange}
					handleFileChange={handleFileChange}
					handleDateChange={handleDateChange}
					handleRemove={handleRemove}
					isLoading={isLoading}
					message={message}
					errors={errors}
					errorMessage={errorMessage}
					isEditing
				/>
			) : (
				'Loading'
			)}
		</Fragment>
	);
};

const mapStateToProps = ({
	events: { isLoading, message, event },
	errors
}) => ({
	isLoading,
	message,
	event,
	errorMessage: errors && errors.message ? errors.message : null,
	errors: errors && errors.validationErrors ? errors.validationErrors : null
});

export default connect(mapStateToProps, {
	editEvent,
	fetchEvent,
	clearEvent,
	deleteEvent,
	clearErrors
})(AddEvent);
