import * as types from '../types';
import { replaceArrayItemById } from '../utils';

const initialState = {
	isLoading: false,
	competitors: [],
	message: null,
	competitor: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.SET_COMPETITORS_LOADING:
			return { ...state, message: null, isLoading: true };
		case types.FETCH_COMPETITORS_SUCCESS:
			return { ...state, competitors: action.payload, isLoading: false };
		case types.FETCH_COMPETITOR_START:
			return { ...state, competitor: null, isLoading: true };
		case types.FETCH_COMPETITOR_SUCCESS:
			return { ...state, competitor: action.payload, isLoading: false };
		case types.ADD_COMPETITOR_SUCCESS:
			return {
				...state,
				competitors: [ ...state.competitors, action.payload ],
				message: action.message,
				isLoading: false
			};
		case types.EDIT_COMPETITOR_SUCCESS:
			return {
				...state,
				competitors: replaceArrayItemById(
					state.competitors,
					action.payload._id,
					action.payload
				),
				competitor: action.payload,
				message: action.message,
				isLoading: false
			};
		case types.DELETE_COMPETITOR_SUCCESS:
			return {
				...state,
				competitors: state.competitors.filter(
					(competitor) => competitor._id !== action.payload
				),
				competitor:null,
				message: action.message,
				isLoading: false
			};
		case types.CLEAR_COMPETITOR:
			return { ...state, competitor: null };
		case types.CLEAR_COMPETITORS_MESSAGE:
			return { ...state, message: null };
		default:
			return state;
	}
};
