import React from 'react'
import ExpansionPanel from '../ExpansionPanel'
import DetailsRow from '../DetailsRow'
import {Typography} from '@material-ui/core'


const InstructorPanel = ({bjjBelt, disciplines, email = 'Nema', phone = 'Nema', ...otherProps}) => {

    return (
        <ExpansionPanel {...otherProps}>
            <DetailsRow label="Pojas">
                <Typography>{bjjBelt}</Typography>
            </DetailsRow>
            <DetailsRow label="Discipline">
                <Typography>{disciplines}</Typography>
            </DetailsRow>
            <DetailsRow label="Telefon">
                <Typography>{phone}</Typography>
            </DetailsRow>
            <DetailsRow label="Email">
                <Typography>{email}</Typography>
            </DetailsRow>
        </ExpansionPanel>
    )
}

export default InstructorPanel