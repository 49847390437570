import React from 'react'
import ExpansionPanel from '../ExpansionPanel'
import DetailsRow from '../DetailsRow'
import {Typography} from '@material-ui/core'


const AcademyPanel = ({address, phone, email, instructors, ...otherProps}) => {

    return (
        <ExpansionPanel {...otherProps}>
            <DetailsRow label="Adresa">
                <Typography>{address}</Typography>
            </DetailsRow>
            <DetailsRow label="Kontakt">
                <Typography>{phone}</Typography>
            </DetailsRow>
            <DetailsRow label="Email">
                <Typography>{email}</Typography>
            </DetailsRow>
            <DetailsRow label="Instruktori">
                <Typography>
                {
                    instructors.length 
                    ?
                    instructors.map(instr => instr.name).join(', ')
                    :
                    'Nema instruktora'
                }
                </Typography>
            </DetailsRow>
        </ExpansionPanel>
    )
}

export default AcademyPanel