import React from 'react';
import { PanelContainer } from './instructors.style';
import { deleteInstructor } from '../../redux/actions/instructors-actions'
import { connect } from 'react-redux';
import InstructorsList from './InstructorsList'

const sortByLocation = (a, b) => {
	if (a.atAcademy.length && a.atAcademy[0].name < b.atAcademy[0].name) {
		return -1
	} else {
		return 1
	}
}

const InstructorsContainer = ({ instructors, isLoading, deleteInstructor, history }) => {
	const handleRemove = id => {
		deleteInstructor(id, history)
	}
	return (
		<PanelContainer>
			<InstructorsList instructors={instructors.sort(sortByLocation)} isLoading={isLoading} handleRemove={handleRemove}/>
		</PanelContainer>
	);
};

const mapStateToProps = ({ instructors }) => ({
	instructors: instructors.instructors,
	isLoading: instructors.isLoading
});

export default connect(mapStateToProps, {deleteInstructor})(InstructorsContainer);
